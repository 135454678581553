export default function Header() {
    return (
        <header className="header">
            <div className='header-container'>
                <div className="logo flex justify-center align-center">
                    <a href="/#"></a>
                </div>
                <nav>
                    <a href="/#">Accueil</a>
                    <a href="/works">Travaux</a>
                    <a href="/skills">Skills</a>
                    <a href="/#experience">Expérience</a>
                    <a href="/#education">Formation</a>
                    <a href="/#contact">Contact</a>
                </nav>
            </div>
            <div class="progress-container">
                <div class="progress-bar" id="scrollProgress"></div>
            </div>
        </header>
    )
}
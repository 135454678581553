import java from "./img/icons/java.svg";
import python from "./img/icons/python.svg";
import postgresql from "./img/icons/postgresql.svg";
import sqlite from "./img/icons/sqlite.svg";
import html5 from "./img/icons/html5.svg";
import css3 from "./img/icons/css3.svg";
import django from "./img/icons/django.svg";
import react from "./img/icons/react.svg";
import teamwork from "./img/icons/teamwork.svg";
import communication from "./img/icons/communication.svg";
import creativity from "./img/icons/creativity.svg";
import autonomy from "./img/icons/autonomy.svg";
import adaptability from "./img/icons/adaptability.svg";
import organized from "./img/icons/organized.svg";
import photoshop from "./img/icons/photoshop.png";
import premiere from "./img/icons/premiere.png";
import javascript from "./img/icons/javascript.png";
import tomcat from "./img/icons/tomcat.png";
import node from "./img/icons/node.png";
import express from "./img/icons/express.png";
import socketio from "./img/icons/socketio.png";
import spring from "./img/icons/spring-boot.svg";
import mongodb from "./img/icons/mongodb.svg";

const mySkills = {
  hard: [
    {
      name: "Java",
      icon: java,
      description:
        "Langage de programmation orienté objet, utilisé pour le développement d'applications de bureau, d'applications mobiles et de sites web.",
      tags: ["développement", "langage", "programmation"],
    },
    {
      name: "Python",
      icon: python,
      description:
        "Langage de programmation multi-paradigme, utilisé pour de la data science, du web-scraping, de l'intelligence artificielle, etc.",
      tags: ["développement", "langage", "programmation"],
    },
    {
      name: "PostgreSQL",
      icon: postgresql,
      description:
        "Système de gestion de base de données relationnelle et objet, utilisé pour le stockage de données.",
      tags: ["base de données", "db", "database", "sql"],
    },
    {
      name: "MongoDB",
      icon: mongodb,
      description:
        "Base de données orientée documents, utilisée pour le stockage de très grandes quantités de données non structurées.",
      tags: ["base de données", "db", "database", "nosql"],
    },
    {
      name: "JavaScript",
      icon: javascript,
      description:
        "Langage de programmation de scripts principalement utilisé pour le développement web.",
      tags: ["développement", "langage", "web", "front-end", "back-end"],
    },
    {
      name: "React",
      icon: react,
      description:
        "Framework JavaScript open-source, utilisé pour le développement d'applications web, principalement pour les interfaces utilisateur.",
      tags: ["développement", "framework", "web", "front-end"],
    },
    {
      name: "HTML",
      icon: html5,
      description: "Langage de balisage utilisé pour la création de pages web.",
      tags: ["développement", "langage", "web", "front-end"],
    },
    {
      name: "CSS",
      icon: css3,
      description:
        "Langage de style utilisé pour la mise en forme de pages web développées en HTML.",
      tags: ["développement", "langage", "web", "front-end"],
    },
    {
      name: "Django",
      icon: django,
      description:
        "Framework web open-source en Python, utilisé pour le développement d'applications web, principalement pour les API.",
      tags: ["développement", "framework", "web", "back-end", "front-end"],
    },
    {
      name: "Spring Boot",
      icon: spring,
      description:
        "Framework Java, notamment utilisé pour le développement d'applications web, principalement pour les API.",
      tags: ["développement", "framework", "web", "back-end"],
    },
    {
      name: "Tomcat",
      icon: tomcat,
      description:
        "Serveur web Java, utilisé pour le déploiement d'applications web.",
      tags: ["développement", "framework", "web", "back-end"],
    },
    {
      name: "React Native",
      icon: react,
      description:
        "Framework JavaScript basé sur React, utilisé pour le développement d'applications mobiles multi-plateformes.",
      tags: ["développement", "framework", "mobile", "front-end"],
    },
    {
      name: "Node.js",
      icon: node,
      description:
        "Environnement d'exécution JavaScript côté serveur, utilisé pour le développement d'applications web.",
      tags: ["développement", "framework", "back-end", "web"],
    },
    {
      name: "Express.js",
      icon: express,
      description:
        "Framework web en Node.js, utilisé pour le développement d'applications web.",
      tags: ["développement", "framework", "web", "back-end"],
    },
    {
      name: "Socket.IO",
      icon: socketio,
      description:
        "Bibliothèque JavaScript, utilisée pour la communication en temps réel entre le client et le serveur.",
      tags: ["développement", "bibliothèque", "web", "back-end"],
    },
    {
      name: "JavaFX",
      icon: java,
      description:
        "Framework Java, utilisé pour le développement d'applications de bureau.",
      tags: ["développement", "framework", "desktop"],
    },
    {
      name: "Photoshop",
      icon: photoshop,
      description:
        "Logiciel de retouche d'images, utilisé pour la création de visuels.",
      tags: ["graphisme", "design"],
    },
    {
      name: "Premiere Pro",
      icon: premiere,
      description:
        "Logiciel de montage vidéo, utilisé pour la création de vidéos.",
      tags: ["montage", "vidéo"],
    },
  ],
  soft: [
    {
      name: "Travail d'équipe",
      icon: teamwork,
      description:
        "Capacité à travailler en équipe, à communiquer et à collaborer avec d'autres personnes.",
    },
    {
      name: "Communication",
      icon: communication,
      description:
        "Capacité à communiquer efficacement avec les autres, à transmettre des informations et à exprimer ses idées.",
    },
    {
      name: "Créativité",
      icon: creativity,
      description:
        "Capacité à créer, à innover et à imaginer de nouvelles idées.",
    },
    {
      name: "Autonomie",
      icon: autonomy,
      description:
        "Capacité à travailler de manière indépendante, à prendre des décisions et à s'auto-motiver.",
    },
    {
      name: "Adaptabilité",
      icon: adaptability,
      description:
        "Capacité à s'adapter à de nouvelles situations, à faire face à des imprévus et à résoudre des problèmes.",
    },
    {
      name: "Organisation",
      icon: organized,
      description:
        "Capacité à organiser son travail, à gérer son temps et à prioriser ses tâches.",
    },
  ],
};

export default mySkills;
